export const capitalizeLanguageRegion = (locale: string): string => {
  if (locale && locale.indexOf('-') > 0) {
    const parts = locale.split('-');
    if (parts.length == 2) return `${parts[0]}-${parts[1].toUpperCase()}`;
  }
  return locale;
};

export const locales = [
  'default',
  'en',
  'de-de',
  'de-at',
  'en-ae',
  'en-au',
  'en-be',
  'en-ca',
  'en-ch',
  'en-dk',
  'en-fi',
  'en-gb',
  'en-id',
  'en-ie',
  'en-my',
  'en-nl',
  'en-no',
  'en-pl',
  'en-se',
  'en-sg',
  'en-th',
  'en-us',
  'es-es',
  'fr-ca',
  'fr-fr',
  'it-it',
  'ja-jp',
  'pt-br',
  'pt-pt',
  'zh-cn',
];

export const resolveCareerLocales = (languageId: number): string[] => {
  if (languageId == 11) {
    return ['fr-ca'];
  } else if (languageId == 13) {
    return ['pt-br'];
  } else {
    const excludes = ['default', 'pt-br', 'fr-ca'];
    return locales.filter((locale) => !excludes.includes(locale));
  }
};

export const resolveCareerLangId = (locale: string | undefined): string => {
  let langId = '1';
  if (locale === 'fr-ca') {
    langId = '11';
  } else if (locale === 'pt-br') {
    langId = '13';
  }
  return langId;
};

export const detectLanguage = (countryCode?: string | null) => {
  if (countryCode) {
    return locales
      .filter((country) => country != 'default')
      .find((country) => country.includes(countryCode.toLowerCase()));
  }
  return undefined;
};

export const localesMapXpToXm: { [key: string]: string } = {
  'fr-be': 'en-be',
  'zh-sg': 'en-sg',
  'nl-nl': 'en-nl',
  'ms-my': 'en-my',
  'se-no': 'en-dk',
  'de-ch': 'en-ch',
  'ar-ae': 'en-ae',
  'pl-pl': 'en-pl',
};

export const localesMapXmToXp: { [key: string]: string } = {
  'en-be': 'fr-be',
  'en-sg': 'zh-sg',
  'en-nl': 'nl-nl',
  'en-my': 'ms-my',
  'en-dk': 'se-no',
  'en-fi': 'se-no',
  'en-se': 'se-no',
  'en-no': 'se-no',
  'en-ch': 'de-ch',
  'en-ae': 'ar-ae',
  'en-pl': 'pl-pl',
  'de-at': 'de-de',
  'en-ie': 'en-gb',
};

export const isMappingNeeded = (path?: string) => {
  const localesToMap = Object.keys(localesMapXpToXm);
  return localesToMap.find((countryCode) => path?.startsWith(`/${countryCode}`));
};

export const getMapping = (local: string) => {
  return localesMapXpToXm[`${local}`];
};

export const isMappingNeededXmToXp = (path?: string) => {
  const localesToMap = Object.keys(localesMapXmToXp);
  return localesToMap.find((countryCode) => path?.startsWith(`/${countryCode}`));
};

export const getMappingXmToXp = (local: string) => {
  return localesMapXmToXp[`${local}`];
};
