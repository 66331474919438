import React, { createContext, useRef, useContext, ReactNode, RefObject } from 'react';

type ScrollContextType = RefObject<HTMLDivElement> | null;

const ScrollContext = createContext<ScrollContextType>(null);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  return <ScrollContext.Provider value={sectionRef}>{children}</ScrollContext.Provider>;
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within ScrollProvider');
  }
  return context;
};
