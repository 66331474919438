/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { createContext, useContext, ReactNode, useState } from 'react';

const LinkContext = createContext<any>(undefined);

export const LinkContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any>({});

  const addData = (newItem: any) => {
    setData(newItem);
  };

  return <LinkContext.Provider value={{ data, addData }}>{children}</LinkContext.Provider>;
};

export const useLink = () => {
  const context = useContext(LinkContext);
  if (!context) {
    throw new Error('useSortFacet must be used within a useSortFacetProvider');
  }

  return context;
};
