/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, ReactNode, useState } from 'react';

const ShowingResultContext = createContext<any>(undefined);

export const ShowingResultContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showResultData, setShowResultData] = useState<any>({});

  const addShowResultData = (newItem: any) => {
    setShowResultData(newItem);
  };

  return (
    <ShowingResultContext.Provider value={{ showResultData, addShowResultData }}>
      {children}
    </ShowingResultContext.Provider>
  );
};

export const useShowResult = () => {
  const context = useContext(ShowingResultContext);
  if (!context) {
    throw new Error('useShowResultFacet must be used within a useShowResultFacetProvider');
  }

  return context;
};
