import React, { createContext, useState, useContext } from 'react';

type Props = {
  children?: React.ReactNode;
};

type ComponentVisibilityContextType = {
  visibleComponents: string[];
  hasScrolled: boolean;
  markComponentVisible: (componentId: string) => void;
  handleScroll: () => void;
};

const ComponentVisibilityContext = createContext<ComponentVisibilityContextType | undefined>(
  undefined
);

// Custom hook to use context easily
export const useComponentVisibility = () => {
  const context = useContext(ComponentVisibilityContext);
  if (!context) {
    throw new Error('useComponentVisibility must be used within a ComponentVisibilityProvider');
  }
  return context;
};

// Provider component
export const ComponentVisibilityProvider: React.FC<Props> = ({ children }) => {
  const [visibleComponents, setVisibleComponents] = useState<string[]>([]);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Mark component as visible when it enters the viewport
  const markComponentVisible = (componentId: string) => {
    if (!visibleComponents.includes(componentId)) {
      setVisibleComponents((prev) => [...prev, componentId]);
    }
  };

  // Set flag to true on first scroll
  const handleScroll = () => {
    if (window.scrollY > 50)
      if (!hasScrolled) {
        setHasScrolled(true);
      }
  };

  return (
    <ComponentVisibilityContext.Provider
      value={{
        visibleComponents,
        hasScrolled,
        markComponentVisible,
        handleScroll,
      }}
    >
      {children}
    </ComponentVisibilityContext.Provider>
  );
};
