/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SearchFacetContextProps {
  filterValue: boolean;
  toggleSearchFacet: (filterId: boolean) => void;
}

const SearchFacetContext = createContext<SearchFacetContextProps | undefined>(undefined);

export const SearchFacetProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filterValue, setFilterValue] = useState<boolean>(false);

  const toggleSearchFacet = (value: boolean) => {
    setFilterValue(value);
  };

  return (
    <SearchFacetContext.Provider value={{ filterValue, toggleSearchFacet }}>
      {children}
    </SearchFacetContext.Provider>
  );
};

export const useSearchFacet = () => {
  const context = useContext(SearchFacetContext);
  //   console.log('context data', context);
  if (!context) {
    throw new Error('useSortFacet must be used within a useSortFacetProvider');
  }

  return context;
};
