import { createContext, ReactNode, useContext } from 'react';

export type NextPlaceholderName =
  | 'root-start'
  | 'root-end'
  | 'before-header'
  | 'after-header'
  | 'before-main'
  | 'after-main'
  | 'before-footer'
  | 'after-footer';

export type NextPlaceholderContent = {
  [placeholderName: string]: ReactNode;
};

export type NextPlaceholderContextType = {
  content: NextPlaceholderContent;
  setContentFor: (name: NextPlaceholderName, content: ReactNode) => void;
};

export const NextPlaceholderContext = createContext<NextPlaceholderContextType | undefined>(
  undefined
);

export const useNextPlaceholderContext = () => {
  const context = useContext(NextPlaceholderContext);
  if (!context) {
    throw new Error('useNextPlaceholderContext must be used within a NextPlaceholderProvider');
  }
  return context;
};
