/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, ReactNode, useState } from 'react';

const SearchJobContext = createContext<any>(undefined);

export const SearchJobContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchJobData, setSearchJobData] = useState<any>({});

  const addSearchJobData = (newItem: any) => {
    setSearchJobData(newItem);
  };

  return (
    <SearchJobContext.Provider value={{ searchJobData, addSearchJobData }}>
      {children}
    </SearchJobContext.Provider>
  );
};

export const useSearchJob = () => {
  const context = useContext(SearchJobContext);
  if (!context) {
    throw new Error('useSearchJobFacet must be used within a useSearchJobFacetProvider');
  }

  return context;
};
