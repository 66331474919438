/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface BookmarkContextProps {
  bookmarkedCards: number[];
  toggleBookmark: (cardId: number) => void;
}

const BookmarkContext = createContext<BookmarkContextProps | undefined>(undefined);

export const BookmarkProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [bookmarkedCards, setBookmarkedCards] = useState<number[]>([]);
  useEffect(() => {
    // Load the bookmarked cards from local storage when component mounts
    const savedBookmarkedCards = localStorage?.getItem('bookmarkedCards');

    if (savedBookmarkedCards) {
      setBookmarkedCards(JSON.parse(savedBookmarkedCards));
    } else {
      setBookmarkedCards([]);
    }

    window.addEventListener('CookieState', () => {
      if (!localStorage?.getItem('CookieState')) {
        //Cookies are allowed

        localStorage?.setItem('bookmarkedCards', JSON.stringify(bookmarkedCards));
      }
    });
  }, []);

  const toggleBookmark = (cardId: number) => {
    setBookmarkedCards((prevBookmarkedCards) => {
      const updatedBookmarkedCards = prevBookmarkedCards.includes(cardId)
        ? prevBookmarkedCards.filter((id) => id !== cardId)
        : [...prevBookmarkedCards, cardId];
      // Save updated data to local storage
      localStorage?.setItem('bookmarkedCards', JSON.stringify(updatedBookmarkedCards));
      window.dispatchEvent(new Event('triggerLocalStorage'));

      // Update component state
      window.dispatchEvent(new Event('localStorageEvent'));
      return updatedBookmarkedCards;
    });
  };

  return (
    <BookmarkContext.Provider value={{ bookmarkedCards, toggleBookmark }}>
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmark = () => {
  const context = useContext(BookmarkContext);
  console.log('context data', context);
  if (!context) {
    throw new Error('useBookmark must be used within a BookmarkProvider');
  }

  return context;
};
