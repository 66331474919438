import { SearchEngine, buildSearchEngine, getOrganizationEndpoints } from '@coveo/atomic-react';
import React, { createContext } from 'react';
import config from 'temp/config';
import { useI18n } from 'next-localization';

type Props = {
  children?: React.ReactNode;
};

type SearchContextProps = {
  getSearchEngine: (scope?: string) => SearchEngine;
};

const engines: { [key: string]: SearchEngine } = {};

const GetSearchEngine = (scope?: string): SearchEngine => {
  const { locale } = useI18n();
  scope = scope || 'Global';
  if (scope in engines) {
    return engines[scope];
  }

  let scopeEngine = buildSearchEngine({
    configuration: {
      accessToken: config.coveoAccessToken,
      organizationId: config.coveoOrganizationId,
      organizationEndpoints: getOrganizationEndpoints(config.coveoOrganizationId),
      search: {
        pipeline: config.coveoPipeline,
        searchHub: config.coveoSearchHub,
        locale: locale(),
      },
    },
  });

  if (scope === 'Jobs') {
    scopeEngine = buildSearchEngine({
      configuration: {
        accessToken: config.coveoCareersAccessToken,
        organizationId: config.coveoOrganizationId,
        organizationEndpoints: getOrganizationEndpoints(config.coveoOrganizationId),
        search: {
          pipeline: config.coveoPipeline,
          searchHub: config.coveoCareersSearchHub,
          locale: locale(),
        },
      },
    });
  }

  engines[scope] = scopeEngine;
  return scopeEngine;
};

const SearchEngineContext = createContext<SearchContextProps>({
  getSearchEngine: GetSearchEngine,
});

// const SearchEngineContext = createContext<SearchEngine[]>([engine, engineClient]);

function SearchEngineProvider({ children }: Props): JSX.Element {
  console.log('---->[SearchContext] Component');
  return (
    <SearchEngineContext.Provider
      value={{
        getSearchEngine: GetSearchEngine,
      }}
    >
      {children}
    </SearchEngineContext.Provider>
  );
}

export { SearchEngineProvider, SearchEngineContext };
