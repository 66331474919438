/* eslint-disable @typescript-eslint/no-empty-function */
import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';
import { SitecorePageProps } from 'lib/page-props';
import Bootstrap from 'src/Bootstrap';
import { BookmarkProvider } from 'lib/contexts/BookmarkContext';
import '../sass/styles.scss';
import { SortFacetProvider } from 'lib/contexts/SortFacetContext';
import React, { useEffect } from 'react';
import { SearchEngineProvider } from 'components/search/search-context/SearchContext';
import { NextPlaceholderProvider } from 'lib/providers/NextPlaceholderProvider';
import { capitalizeLanguageRegion } from 'lib/utils';
import { SearchFacetProvider } from 'lib/contexts/SearchFacetContext';
import { LinkContextProvider } from 'lib/contexts/AllLinksContext';
import { ShowingResultContextProvider } from 'lib/contexts/ShowingResultContext';
import { SearchJobContextProvider } from 'lib/contexts/SearchJobContext';
import { ScrollProvider } from 'lib/contexts/ScrollContext';
import { useRouter } from 'next/router';
import { ComponentVisibilityProvider } from '../lib/contexts/ComponentVisibilityContext';

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const router = useRouter();
  useEffect(() => {
    router.prefetch = async () => {};
  }, [router]);
  return (
    <>
      <Bootstrap {...pageProps} />
      {/*
        // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
        // Note Next.js does not (currently) provide anything for translation, only i18n routing.
        // If your app is not multilingual, next-localization and references to it can be removed.
      */}
      <I18nProvider lngDict={dictionary} locale={capitalizeLanguageRegion(pageProps.locale)}>
        <NextPlaceholderProvider>
          <ComponentVisibilityProvider>
            <SearchEngineProvider>
              <BookmarkProvider>
                <SortFacetProvider>
                  <SearchFacetProvider>
                    <LinkContextProvider>
                      <ShowingResultContextProvider>
                        <SearchJobContextProvider>
                          <ScrollProvider>
                            <Component {...rest} />
                          </ScrollProvider>
                        </SearchJobContextProvider>
                      </ShowingResultContextProvider>
                    </LinkContextProvider>
                  </SearchFacetProvider>
                </SortFacetProvider>
              </BookmarkProvider>
            </SearchEngineProvider>
          </ComponentVisibilityProvider>
        </NextPlaceholderProvider>
      </I18nProvider>
    </>
  );
}

export default App;
