/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SortFacetContextProps {
  facetValue: string;
  toggleSortFacet: (filterId: string) => void;
}

const SortFacetContext = createContext<SortFacetContextProps | undefined>(undefined);

export const SortFacetProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [facetValue, setFacetValue] = useState<string>('relevent');

  const toggleSortFacet = (value: string) => {
    setFacetValue(value);
  };

  return (
    <SortFacetContext.Provider value={{ facetValue, toggleSortFacet }}>
      {children}
    </SortFacetContext.Provider>
  );
};

export const useSortFacet = () => {
  const context = useContext(SortFacetContext);
  //   console.log('context data', context);
  if (!context) {
    throw new Error('useSortFacet must be used within a useSortFacetProvider');
  }

  return context;
};
