import {
  NextPlaceholderContent,
  NextPlaceholderContext,
  NextPlaceholderName,
} from 'lib/contexts/NextPlaceholderContext';
import { useState, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const NextPlaceholderProvider = ({ children }: Props) => {
  const [content, setContent] = useState<NextPlaceholderContent>({});

  const setContentFor = (name: NextPlaceholderName, newContent: ReactNode) => {
    setContent((prevContent) => ({
      ...prevContent,
      [name]: newContent,
    }));
  };

  return (
    <NextPlaceholderContext.Provider value={{ content, setContentFor }}>
      {children}
    </NextPlaceholderContext.Provider>
  );
};
